import React from "react";
import MapLogo from "../../assets/images/nid/map-logo.jpeg";
import GovtSign from "../../assets/images/nid/govt-sign.png";
import FlowerLogo from "../../assets/images/nid/flower-logo.png";
import BarCode417 from "../BarCode417";

const NIDCard = ({
  info: {
    address,
    birth_place,
    blood_group,
    date_of_birth,
    father_name,
    mother_name,
    name_bn,
    name_en,
    nid_no,
    nid_photo,
    nid_pin,
    nid_signature,
    barcode_data,
  },
}) => {
  function generate_bangla_date() {
    return new Date()
      .toLocaleDateString("bn")
      .split("/")
      .map((d, i) => {
        if ((i === 1 || i === 0) && d.length === 1) {
          return "০" + d;
        } else {
          return d;
        }
      })
      .join("/");
  }
  return (
    <>
      <div
        className="flex items-start gap-x-2 bg-transparent w-fit mx-auto"
        id="nid_wrapper"
      >
        <div id="nid_front" className="w-full border-[1.999px] border-black">
          <header className="px-1.5 flex items-start gap-x-2 justify-between relative">
            <img
              className="w-[38px] absolute top-1.5 left-[4.5px]"
              src={MapLogo}
              alt=""
            />
            <div className="w-full h-[60px] flex flex-col justify-center">
              <h3
                style={{ fontSize: "20px" }}
                className="text-center font-medium tracking-normal  pl-11 bn leading-5"
              >
                <span style={{ marginTop: "1px", display: "inline-block" }}>
                  গণপ্রজাতন্ত্রী বাংলাদেশ সরকার
                </span>
              </h3>
              <p
                className="text-[#007700] text-right tracking-[-0rem] leading-3"
                style={{
                  fontSize: "11.46px",
                  fontFamily: "arial",
                  marginBottom: "-0.02px",
                }}
              >
                Government of the People's Republic of Bangladesh
              </p>
              <p
                className="text-center font-medium pl-10 leading-4"
                style={{ paddingTop: "0px" }}
              >
                <span
                  className="text-[#ff0002]"
                  style={{
                    fontSize: "10px",
                    fontFamily: "arial",
                  }}
                >
                  National ID Card
                </span>
                <span
                  className="ml-1"
                  style={{
                    display: "inline-block",
                  }}
                >
                  <span style={{ fontSize: "13px", fontFamily: "arial" }}>
                    /
                  </span>
                </span>
                <span className="bn ml-1" style={{ fontSize: "13.33px" }}>
                  জাতীয় পরিচয় পত্র
                </span>
              </p>
            </div>
          </header>
          <div className="w-[100%] border-b-[1.9999px] border-black"></div>

          <div className="pt-[3.8px] pr-1 pl-[2px] bg-center w-full flex justify-between gap-x-2 pb-5 relative">
            <div className="absolute inset-x-0 top-[2px] mx-auto z-10 flex items-start justify-center">
              <img
                className="ml-[20px] w-[125px] h-[116px]"
                src={FlowerLogo}
                alt=""
              />
            </div>

            <div className="relative z-50">
              <img
                style={{ marginTop: "-2px" }}
                className="w-[68.2px] h-[78px]"
                alt=""
                src={nid_photo}
              />
              <div
                className="text-center text-xs flex items-start justify-center pt-[5px] w-[68.2px] mx-auto h-[38.5px] overflow-hidden"
                id="card_signature"
              >
                {nid_signature?.length > 10 ? (
                  <img src={nid_signature} alt="" />
                ) : (
                  <span style={{ fontFamily: "Comic sans ms" }}>
                    {nid_signature}
                  </span>
                )}
              </div>
            </div>

            <div className="w-full relative z-50">
              <div style={{ height: "5px" }}></div>
              <div className="flex flex-col gap-y-[10px]">
                <div>
                  <p
                    className="space-x-4 leading-3"
                    style={{ paddingLeft: "1px" }}
                  >
                    <span className="bn" style={{ fontSize: "16.53px" }}>
                      নাম:
                    </span>
                    <span
                      className="font-bold uppercase"
                      style={{
                        fontSize: "16px",
                        paddingLeft: "3px",
                        fontFamily: "Kalpurush",
                      }}
                    >
                      {name_bn}
                    </span>
                  </p>
                </div>

                <div>
                  <p
                    className="space-x-2 leading-3"
                    style={{
                      marginBottom: "-1.4px",
                      marginTop: "1.4px",
                      paddingLeft: "1px",
                    }}
                  >
                    <span style={{ fontSize: "10px" }}>Name: </span>
                    <span style={{ fontSize: "13.73px", paddingLeft: "1px" }}>
                      {name_en}
                    </span>
                  </p>
                </div>

                <div>
                  <p
                    className="bn space-x-3 leading-3"
                    style={{ paddingLeft: "1px" }}
                  >
                    <span style={{ fontSize: "14px" }}>পিতা: </span>
                    <span
                      style={{ fontSize: "14px", transform: "scaleX(0.724)" }}
                      id="card_father_name"
                    >
                      {father_name}
                    </span>
                  </p>
                </div>

                <div>
                  <p
                    className="bn space-x-3 leading-3"
                    style={{ marginTop: "-2.5px", paddingLeft: "1px" }}
                  >
                    <span style={{ fontSize: "14px" }}>মাতা: </span>
                    <span
                      style={{ fontSize: "14px", transform: "scaleX(0.724)" }}
                      id="card_mother_name"
                    >
                      {mother_name}
                    </span>
                  </p>
                </div>
                <div
                  className="leading-4"
                  style={{ fontSize: "12px", marginTop: "-1.2px" }}
                >
                  <p style={{ marginTop: "-2px" }}>
                    <span>Date of Birth: </span>
                    <span id="card_date_of_birth" className="text-[#ff0000]">
                      {date_of_birth}
                      {/* {new Date(date_of_birth?.trim())
                        .toUTCString()
                        .split(" ")
                        .splice(1, 3)
                        .join(" ")} */}
                    </span>
                  </p>
                </div>
                <div
                  className="-mt-0.5 leading-4"
                  style={{ fontSize: "12px", marginTop: "-5px" }}
                >
                  <p style={{ marginTop: "-3.5px" }}>
                    <span>ID NO: </span>
                    <span
                      className="text-[#ff0000] font-semibold"
                      id="card_nid_no"
                    >
                      {nid_no}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="nid_back" className="w-full border-[1.999px] border-[#000]">
          <header className="h-[32px] flex items-center px-2 tracking-wide text-left">
            <p
              className="bn"
              style={{
                lineHeight: "13px",
                fontSize: "11.33px",
                letterSpacing: "0.05px",
                marginBottom: "-0px",
              }}
            >
              এই কার্ডটি গণপ্রজাতন্ত্রী বাংলাদেশ সরকারের সম্পত্তি। কার্ডটি
              ব্যবহারকারী ব্যতীত অন্য কোথাও পাওয়া গেলে নিকটস্থ পোস্ট অফিসে জমা
              দেবার জন্য অনুরোধ করা হলো।
            </p>
          </header>
          <div className="w-[100%] border-b-[1.999px] border-black"></div>
          <div
            className="px-1 pt-[3px] h-[66px] grid grid-cols-12 relative"
            style={{ fontSize: "12px" }}
          >
            <div
              className="col-span-1 bn px-1 leading-[11px]"
              style={{ fontSize: "11.73px", letterSpacing: "-0.12px" }}
            >
              ঠিকানা:
            </div>
            <div
              className="col-span-11 pl-[7px] text-left bn leading-[11px]"
              id="card_address"
              style={{
                fontSize: "11.73px",
                letterSpacing: "-0.14px",
              }}
            >
              {/* : ঃ */}
              {address}
            </div>

            <div className="col-span-12 mt-auto flex justify-between">
              <p
                className="bn flex items-center font-medium"
                style={{ marginBottom: "-5px", paddingLeft: "0px" }}
              >
                <span style={{ fontSize: "11.6px" }}>রক্তের গ্রুপ</span>
                <span
                  style={{
                    display: "inline-block",
                    marginLeft: "3px",
                    marginRight: "3px",
                  }}
                >
                  <span>
                    <span
                      style={{
                        display: "inline-block",
                        fontSize: "11px",
                        fontFamily: "arial",
                        marginTop: "2px",
                      }}
                    >
                      /
                    </span>
                  </span>
                </span>
                <span style={{ fontSize: "9px" }}>Blood Group:</span>
                <b
                  style={{
                    fontSize: "9.33px",
                    marginBottom: "-1.7px",
                    display: "inline-block",
                  }}
                  className="text-[#ff0000] mx-1 font-bold sans w-5"
                  id="card_blood"
                >
                  {blood_group}
                </b>
                <span style={{ fontSize: "10.66px" }}> জন্মস্থান: </span>
                <span
                  className="ml-1"
                  id="card_birth_place"
                  style={{ fontSize: "10.66px" }}
                >
                  {birth_place}
                </span>
              </p>
              <div className="text-gray-100 bg-black absolute -bottom-[1px] w-[30.5px] h-[13px] -right-[1px]">
                <span
                  className="absolute inset-0 m-auto bn flex items-center text-[#fff]"
                  style={{ fontSize: "10.66px" }}
                >
                  <span>মূদ্রণ:</span>
                  <span className="block ml-[3px]">০১</span>
                </span>
              </div>
            </div>
          </div>

          <div className="w-[100%] border-b-[1.999px] border-black"></div>
          <div className="py-1 pl-2 pr-1">
            <img
              className="w-[78px] ml-[18px] -mb-[3px]"
              src={GovtSign}
              alt=""
            />
            <div className="flex justify-between items-center -mt-[5px]">
              <p className="bn" style={{ fontSize: "14px" }}>
                প্রদানকারী কর্তৃপক্ষের স্বাক্ষর
              </p>
              <span
                className="pr-4 bn"
                style={{ fontSize: "12px", paddingTop: "1px" }}
              >
                প্রদানের তারিখ:
                <span className="ml-2.5">{generate_bangla_date()}</span>
              </span>
            </div>
            <div className="mt-1 w-full">
              <BarCode417
                value={`<pin>${nid_pin}</pin><name>${name_en}</name><DOB>${date_of_birth}</DOB><FP></FP><F>Right Index</F><TYPE>A</TYPE><V>2.0</V><ds>${barcode_data}</ds>`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NIDCard;
