/*
new Date(
                  date_of_birth?.trim()
                )
                  .toUTCString()
                  .split(" ")
                  .splice(1, 3)
                  .join(
                    " "
                  )

                    const [imageData, setImageData] = useState("");
  useEffect(() => {
    fetch(
      `https://barcode-generator.onrender.com/api/v1/barcode/pdf417?value=${value}`
    )
      .then((res) => res.text())
      .then((result) => setImageData(result));
  }, []);

  return <img className="w-full h-[41px]" src={imageData} alt="" />;


    */
import { useEffect, useState } from "react";

const BarCode417 = ({ value }) => {
  return (
    <img
      className="w-full h-[39px]"
      src={`https://barcode.tec-it.com/barcode.ashx?data=${value}&code=PDF417`}
      alt=""
    />
  );
};

export default BarCode417;
